@use '../variables';
@use '../mixins';

.MainMenu {
  @include mixins.box-shadow;
  color: rgb(130, 130, 130);
  text-align: left;
  position: relative;
  z-index: 1;
  .title {
    background-color: variables.$turquoise;
    color: white;
    display: flex;
    align-items: center;
    padding: 1rem 2.25rem;
    font: normal normal normal 16px/1.2 Roboto;
    height: 5rem;
    & > *:first-child {
      flex-shrink: 0;
      margin-right: 1rem;
      max-height: 3.75rem;
      max-width: 50%;
    }
    span {
      flex-grow: 1;
    }
    button {
      flex-shrink: 0;
      margin-left: 1rem;
      cursor: pointer;
      @include mixins.md-up {
        display: none;
      }
    }
  }
  & > ul {
    display: none;
    @include mixins.md-up {
      display: block;
    }
  }
  &.expanded > ul {
    display: block;
  }
  ul {
    list-style-type: none;
    background: white;
    margin: 0;
    padding: 0;
    & > .restricted {
      color: variables.$gray;
      pointer-events: none;
    }
  }
  & > ul > li {
    padding: 1.125rem 0;
  }
  & > ul > li:not(:first-child) {
    border-top: 2px solid #eeeff0;
  }
  ul div {
    font: normal normal bold 12px/1.2 Roboto;
    color: #abafb3;
    text-transform: uppercase;
    padding: 1.125rem 2.25rem;
  }
  button {
    all: unset;
    cursor: pointer;
    box-sizing: border-box;
  }
  li a, li button {
    display: flex;
    width: 100%;
    font-size: 1rem;
    color: inherit;
    text-decoration: none;
    padding: 1.125rem 2.25rem;
    &.active, &:hover {
      background-color: lighten(variables.$light-turquoise, 3%);
      color: variables.$turquoise;
      border-left: 3px solid variables.$turquoise;
      padding-left: calc(2.25rem - 3px);
    }
    &:disabled {
      opacity: .5;
      pointer-events: none;
    }
    svg {
      margin-right: .5rem;
      width: 16px;
      flex-shrink: 0;
    }
    span {
      position: relative;
      top: 3px;
      @include mixins.md-up {
        top: 2px;
      }
    }
  }
}
