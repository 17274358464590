@use 'mixins';
@use 'variables';

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto-Regular'),
  url(./fonts/roboto-latin-400.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto-Bold'),
  url(./fonts/roboto-latin-700.woff2) format('woff2');
}

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  @include mixins.md-up {
    font-size: 18px;
  }
}

body {
  margin: 0;
  background: variables.$light-gray;
  color: variables.$darker-gray;
  font-family: Roboto, sans-serif;
  line-height: 1.2;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mt-1-75 {
  margin-top: 1.75rem !important;
}

.text-center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.gradient {
  &.purple {
    --gradient-start: #{map-get(variables.$gradient-purple, "start")};
    --gradient-stop: #{map-get(variables.$gradient-purple, "stop")};
  }
  &.orange {
    --gradient-start: #{map-get(variables.$gradient-orange, "start")};
    --gradient-stop: #{map-get(variables.$gradient-orange, "stop")};
  }
  &.turquoise {
    --gradient-start: #{map-get(variables.$gradient-turquoise, "start")};
    --gradient-stop: #{map-get(variables.$gradient-turquoise, "stop")};
  }
}
